import { Component } from 'react';
import { AppProps } from 'single-spa';
import { TAppConf, TResource, TUser } from '@gf-tech/types';

import { baseURL, http, store } from './utils';
import { RootRouter } from './components/RootRouter';

type TState = {
    router: TResource;
};
export default class Root extends Component<AppProps & TAppConf, TState> {
    constructor(props: AppProps & TAppConf) {
        super(props);

        const appConf = props.appConf;
        store.namespaces(appConf.namespace);
        http.config({ store, baseURL: appConf.apiHost + baseURL });

        const userInfo: TUser = store.get('userInfo');
        const pay = userInfo.resList.find(item => item.uriRoute === '/pay');
        this.state = {
            router: pay,
        };
    }

    render() {
        return (
            <div style={{ flex: 1, width: 0, margin: '24px' }}>
                <RootRouter base='/pay' router={this.state.router}></RootRouter>
            </div>
        );
    }
}

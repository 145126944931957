import { Store, Http, IHttpAxiosInstance } from '@gf-tech/utils';
// import { Store, Http, IHttpAxiosInstance } from '../../../micro-utils/src/gf-tech-utils';

const store = new Store();

const baseURL = '/msx-pay';

const http: IHttpAxiosInstance = new Http().create();

export { http, store, baseURL };
